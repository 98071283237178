.off-canvas-content {
	position: relative;
}

.site-header {
	position: relative;

	.menu-container {
		float: left;
		width: 100%;

		.menu-button {
			width: 90px;
			padding: 40px 30px; 
			cursor: pointer;

			@include breakpoint(medium) {
				width: 110px;
				padding: 40px;
			}

			span {
				background: $xxLight;
				width: 30px;
				height: 1px;
				display: block;
				position: relative;

				&:before {
					display: block;
					position: absolute;
					content: "";
					width: 25px;
					height: 1px;
					top: -7px;
					left: 0px;
					background: $xxLight;
					transition: .4s all;
				}

				&:after {
					display: block;
					position: absolute;
					content: "";
					width: 15px;
					height: 1px;
					top: 7px;
					left: 0px;
					background: $xxLight;
					transition: .6s all;
				}
			}
		}  

		&:hover .menu-button span:before,
		&:hover .menu-button span:after {
			width: 30px;
		}
	}

	.tim-logo {
		position: absolute;
		width: 80px;
		height: 80px;
		top: 0;
		right: 0;
		left: 0;
		margin: 0 auto;
		padding: 10px;
		text-align: center;

		svg {
			height: 60px;

			.st0 {
				fill:$xxLight;
			}
		}
	}
}

.dark .site-header {
	.menu-container .menu-button {
		span {
			background: $black;

			&:before, &:after {
				background: $black;
			}
		}
	}

	.tim-logo {
		svg {

			.st0 {
				fill: $black !important;
			}
		}
	}
}



