.project-hero {
	position: fixed;
	position: sticky;
	height: 100vh;
	width: 100%;
	background: center no-repeat;
	background-size: cover;
	top: 0;
	right: 0;
	z-index: -9999;
	}

.project-container {
  position: relative;
  min-height: 100vh;

  &.archive {
  	padding-top: 50vh;
  }

	.project-body {
		position: relative;
		margin: 0;
		padding: 60px 30px;
		background-color: $xxLight;
		text-align: center;

		@include breakpoint(medium) {
			padding: 40px;
			margin: 0 80px;
			padding: 120px 80px;
		}

		.project-title {
			position: absolute;
			width: 100%;
			height: 100vh;
			left: 0;
			right: 0;
			top: -100vh;
			text-align: center;

			.title-content {
			  	text-align: center;
			  	position: absolute;
			  	top: 50%;
			  	right: 0;
			  	left: 0;
			  	transform: translateY(-50%);

			  	p {
			  		font-family: $serif;
			  		font-size: 14px;
			  		letter-spacing: -0.01em;
			  		line-height: 1.5;
			  		color: $xxLight;
			  		margin: 10px 0 0;
			  	}

			  	h1 {
			  		font-family: $sans-heavy;
			  		font-size: 24px;
				  	color: $xxLight;
			  		margin: 0;
			  		letter-spacing: -0.025em;

			  		@include breakpoint(medium) {
						font-size: 32px;
					}
			  	}
			  }
		}

		.down_line {
			position: absolute;
			width: 1px;
			height: 100px;
			left: 0;
			right: 0;
			top: -100px;
			margin: 0 auto;

			&:before {
				width: 1px;
				height: 80px;
				content: "";
				background-color: $xxLight;
				position: absolute;
				bottom: 0;
				animation: pulse 3s infinite;
			}

			@keyframes pulse {
				0% {
					height: 100px;
				}

				50% {
					height: 50px;
				}

				100% {
					height: 100px;
				}
			}
		}

		.project-body_container {
			max-width: 1280px;
			margin: 0 auto;

			.project-detail {
				text-align: left;
				font-family: $sans-med;
				font-size: 16px;
				color: $grey;
				padding: 0;

				@include breakpoint(medium) {
					padding: 0 40px;
				}

				h4 {
					font-family: $sans-heavy;
					letter-spacing: -0.05em;
					font-size: 20px;
					color: $black;
					margin-bottom: 2px;
					display: inline-block;

					@include breakpoint(medium) {
						font-size: 24px;
					}
				}

				p {
					font-family: $serif;
					letter-spacing: -0.01em;
				}

				a {
					font-size: 14px;
					color: $grey;
					display: inline-block;
					line-height: 1;
					margin: 0 0 40px;
					position: relative;

					@include breakpoint(medium) {
						margin: 10px 0 0;
					}

					&:after {
						position: absolute;
						content: "";
						bottom: -2px;
						left: 0;
						width: 0%;
						height: 1px;
						background-color: $grey;
						transition: 0.4s all;
					}

					&:hover:after {
						width: 100%;
					}
				}
			}

			.project-intro {
				color: $mid-grey;
				font-size: 16px;
				text-align: left;
				padding: 0;

				@include breakpoint(medium) {
					padding: 0 40px;
				}

				p {
					line-height: 2;
					letter-spacing: -0.01em;
					font-family: $serif;
				}
			}

			img {
				margin-top: 60px;

				@include breakpoint(medium) {
					margin-top: 120px;
				}
			}

			.more-projects {
				padding: 60px 0 0;
				text-align: center;
				width: 100%;
				line-height: 1;
				font-size: 0;

				@include breakpoint(medium) {
					padding: 120px 0 0;
				}

				a {
					font-family: $sans-book;
					font-size: 12px;
					color: $black;
					line-height: 1;
					display: inline-block;
					letter-spacing: .2em;
					position: relative;
					padding: 10px 0;
				}

				.prev {
					margin: 0 100px 0 0;				
					
					&:before {
						position: absolute;
						content: "";
						top: 15px;
						right: -100px;
						width: 80px;
						background-color: $light;
						height: 1px;
						transition: all 0.4s;
					}

					&:after {
						position: absolute;
						content: "";
						bottom: 8px;
						left: 0;
						width: 0%;
						height: 1px;
						background-color: $grey;
						transition: 0.4s all;
					}

					&:hover:after {
						width: 100%;
					}
				}

				.next {
					margin: 0 0 0 100px;

					&:hover {

					}
						
					&:before {
						position: absolute;
						content: "";
						top: 15px;
						left: -100px;
						width: 80px;
						background-color: $light;
						height: 1px;
						transition: all 0.4s;
					}

					&:after {
						position: absolute;
						content: "";
						bottom: 8px;
						left: 0;
						width: 0%;
						height: 1px;
						background-color: $grey;
						transition: 0.4s all;
					}

					&:hover:after {
						width: 100%;
					}
				}
			}
		}
	}

}






