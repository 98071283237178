@font-face {
    font-family: 'GCB';
    src: url('../fonts/GalaxieCopernicusBook.eot');
    src: url('../fonts/GalaxieCopernicusBook.eot') format('embedded-opentype'),
         url('../fonts/GalaxieCopernicusBook.woff2') format('woff2'),
         url('../fonts/GalaxieCopernicusBook.woff') format('woff'),
         url('../fonts/GalaxieCopernicusBook.ttf') format('truetype'),
         url('../fonts/GalaxieCopernicusBook.svg#GalaxieCopernicusBook') format('svg');
}

@font-face {
    font-family: 'AvHeavy';
    src: url('../fonts/Avenir_4.eot');
    src: url('../fonts/Avenir_4.eot') format('embedded-opentype'),
         url('../fonts/Avenir_4.woff2') format('woff2'),
         url('../fonts/Avenir_4.woff') format('woff'),
         url('../fonts/Avenir_4.ttf') format('truetype'),
         url('../fonts/Avenir_4.svg#Avenir_4') format('svg');
}

@font-face {
    font-family: 'AvMed';
    src: url('../fonts/Avenir_8.eot');
    src: url('../fonts/Avenir_8.eot') format('embedded-opentype'),
         url('../fonts/Avenir_8.woff2') format('woff2'),
         url('../fonts/Avenir_8.woff') format('woff'),
         url('../fonts/Avenir_8.ttf') format('truetype'),
         url('../fonts/Avenir_8.svg#Avenir_8') format('svg');
}

@font-face {
    font-family: 'AvBook';
    src: url('../fonts/Avenir_0.eot');
    src: url('../fonts/Avenir_0.eot') format('embedded-opentype'),
         url('../fonts/Avenir_0.woff2') format('woff2'),
         url('../fonts/Avenir_0.woff') format('woff'),
         url('../fonts/Avenir_0.ttf') format('truetype'),
         url('../fonts/Avenir_0.svg#Avenir_0') format('svg');
}
