/* COLORS */

$xxLight: #f9f9f9;
$xLight: #f5f5f5;
$light: #bbb;
$grey: #888;
$mid-grey: #666;
$black: #111;
$blue: #3F51B5;

/* FONTS */

$serif: 'GCB', serif;
$sans-med: 'AvMed', sans-serif;
$sans-book: 'AvBook', sans-serif;
$sans-heavy: 'AvHeavy', sans-serif;

h1, h2, h3, h4, h5, h6 {
	font-family: $serif;
	line-height: 1.25;
	font-weight: 400;
}

p {
	line-height: 2;
	font-family: $sans-book;
}

a {
	color: $blue;
}

:focus {
	outline: none;
}

.column, .columns {
	padding: 0;
}

.row {
	max-width: 1280px;
}