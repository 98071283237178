.off-canvas {
	background-color: #111;
}

.js-off-canvas-overlay {
	background: none;
}

.position-left {
	width: 285px;
	-webkit-transform: translateX(-285px);
	-ms-transform: translateX(-285px);
	transform: translateX(-285px);

	@include breakpoint(medium) {
		width: 400px;
		-webkit-transform: translateX(-400px);
   		-ms-transform: translateX(-400px);
    	transform: translateX(-400px);
	}
}

.off-canvas-content.is-open-left.has-transition-push {
	-webkit-transform: translateX(285px);
	-ms-transform: translateX(285px);
	transform: translateX(285px);

	@include breakpoint(medium) {
		-webkit-transform: translateX(400px);
    	-ms-transform: translateX(400px);
    	transform: translateX(400px);
	}
}


.off-canvas .menu {
	padding: 30px;

	.menu-title {
		a {
			padding: 0;
			font-family: $serif;
			letter-spacing: -0.01em;
			color: $light;
			font-size: 16px;
			line-height: 1.25;
			margin-bottom: 50px;
		}
	}

	.menu-link {
		line-height: 0;

		a {
			font-family: $sans-med;
			letter-spacing: -0.025em;
			color: $xxLight;
			font-size: 16px;
			line-height: 1;
			padding: 0;
			margin-bottom: 30px;
			display: inline-block;
			position: relative;

			&:after {
				position: absolute;
				content: "";
				bottom: -2px;
				left: 0;
				width: 0%;
				height: 1px;
				background-color: $xxLight;
				transition: 0.4s all;
			}

			&:hover:after {
				width: 100%;
			}
		}
	}
}