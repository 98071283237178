.owl-carousel-container {
	margin: 0 30px 80px;
	position: relative;
	width: calc(100vw - 60px);

	@include breakpoint(medium) {
		margin: 0 40px 80px;
		width: calc(100vw - 80px);
	}
}

.owl-carousel .owl-stage-outer {
	height: calc(100vh - 160px);
	width: 100%;
}

.owl-carousel .owl-stage {
	height: 100%;
	width: 100%;
}

.owl-carousel .owl-item {
	height: 100%;
	width: 100%;
	background-color: $xLight;

	&:nth-child(even) {
		background-color: $xLight;
	} 

	.slide-image {
		height: 100%;
		background: center no-repeat;
		background-size: contain;
	}
}

.owl-theme .owl-nav {
	margin: 0;

	.owl-prev {
		left: 0;
		transform: translate(-50%, -50%) rotate(-90deg);
	}

	.owl-next {
		right: 0;
		transform: translate(50%, -50%) rotate(90deg);
	} 

	/*&:after {
		content: "swipe";
		position: absolute;
		color: $grey;
		font-family: $sans-book;
		font-size: 12px;
		letter-spacing: .4em;
		line-height: 1;
		margin: 0 auto;
		padding: 1em;
		padding-left: 1.4em;
		text-transform: uppercase;
		bottom: -18px;
		right: 0;
		left: 0;
		border-radius: 0;
		background: none;
		cursor: pointer;
	}*/

	.owl-next,
	.owl-prev {
		position: absolute;
		color: $black;
		font-family: $sans-book;
		font-size: 12px;
		letter-spacing: .4em;
		line-height: 1;
		margin: 0;
		padding: 1em;
		padding-left: 1.4em;
		text-transform: uppercase;
		top: calc(50% + 40px);
		border-radius: 0;
		background: none;
		cursor: pointer;

		&:hover {
			background: none;
			color: $black;
		}

		&:after {
			position: absolute;
			content: "";
			bottom: -2px;
			left: 0;
			width: 0%;
			height: 1px;
			background-color: $light;
			transition: 0.4s all;
		}

		&:hover:after {
			width: 100%;
		}
	}
}

.owl-theme .owl-dots {
	display: none;
}

.owl-carousel-container {
	.down_line {
			position: absolute;
			width: 1px;
			height: 100px;
			left: 0;
			right: 0;
			bottom: -80px;
			margin: 0 auto;
			z-index: 999;

			&:before {
				width: 1px;
				height: 80px;
				content: "";
				background-color: $light;
				position: absolute;
				bottom: 0;
				animation: pulse 3s infinite;
			}

			@keyframes pulse {
				0% {
					height: 100px;
				}

				50% {
					height: 50px;
				}

				100% {
					height: 100px;
				}
			}
		}
}

.client-logos {
	min-height: 100vh;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
	padding: 80px 30px 60px;

	@include breakpoint(medium) {
		padding: 80px 40px 40px;
	}

	.row {
		width: 100%;
	}
}

.client-title {
	font-family: $serif;
	letter-spacing: -0.01em;
	font-size: 16px;
	color: $black;
	margin-bottom: 40px;
	display: inline-block;
	text-align: center;
	width: 100%;

	@include breakpoint(medium) {
		font-size: 24px;
	}
}

.clients-list {
  font-size: 0;
  margin: 0;
  width: 100%;
}

.client-logo {
  display: inline-block;
  width: 33%;
  margin: 0;
  padding: 0;

  @include breakpoint(medium) {
		width: 20%;
	}
}

.client-logo img {
  max-width: 80%;
  height: 100%;
  margin: 20px 0;
  padding: 0;

	@include breakpoint(medium) {
		max-width: 70%;
		margin: 40px 0;
	}
	@include breakpoint(large) {
		max-width: 50%;
	}
}
